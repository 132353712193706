import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useRef, useState } from 'react'

export type SideMenuProps = PropsWithChildren<{
  open: boolean
  onClose?: Dispatch<SetStateAction<boolean>>
}>

const mediaMatch = window.matchMedia('(min-width: 1280px)')

export function SideMenu({ children, open, onClose }: SideMenuProps) {
  const [largeScreen, setLargeScreen] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setLargeScreen(e.matches)
    }
    mediaMatch.addEventListener('change', handler)
    return () => {
      mediaMatch.removeEventListener('change', handler)
    }
  }, [])

  const linkRef = useRef<any>(null)

  return (
    <>
      {largeScreen ? (
        // top-[4.75rem] is height of app bar
        // the side menu as a drawer and the modal: z-50, the header: z-40, the normal side menu:z-30
        <div className="fixed top-[4.5rem] md:top-[4.75rem] bottom-0 overflow-y-auto bg-white z-30 pb-4">
          <div className="fixed border-r border-gray-200 h-full left-64" />
          <div className="flex flex-col w-64 relative">{children}</div>
        </div>
      ) : (
        <Transition appear show={open}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50"
            initialFocus={linkRef}
            onClose={() => onClose && onClose(false)}
            open={open}
          >
            <TransitionChild
              as="div"
              enter="transition-opacity ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed bg-black/30 inset-0" />
            </TransitionChild>
            <TransitionChild
              as="div"
              enter="transition ease duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel
                as="div"
                className="fixed inset-0 flex flex-col w-3/4 sm:w-64 overflow-y-auto h-[calc(100dvh)] bg-white z-0 shadow-2xl shadow-black/50 pb-4"
                ref={linkRef}
              >
                {children}
              </DialogPanel>
            </TransitionChild>
          </Dialog>
        </Transition>
      )}
    </>
  )
}
