import { PropsWithChildren } from 'react'

import { ErrorBoundary } from './ErrorBoundary'
import { ToastContainer } from './ToastContainer'

export function ErrorHandlingContextProvider({
  children,
  wrap,
}: PropsWithChildren<{
  wrap?: boolean
}>) {
  const inside = (
    <>
      <ToastContainer />
      <ErrorBoundary title="Unexpected Error">{children}</ErrorBoundary>
    </>
  )

  if (!wrap) {
    return inside
  }

  return <div>{inside}</div>
}
