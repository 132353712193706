import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'

import { useAuthenticationContext } from '@northvolt/cloud-auth'
import { Dropdown } from '@northvolt/form'

import { isUserAllowedToProvision } from '../../auth'
import forest from '../../images/forest.jpg'
import { Provisioning } from '../Management'
import { SystemType } from '../types'

import { QrButton } from './QRButton'
import { Search } from './Search'

type SystemSelection = { label: string; value: SystemType }
const systemTypes: SystemSelection[] = [
  { label: 'All', value: 'all' },
  { label: 'Voltpacks', value: 'voltpacks' },
  { label: 'Cores', value: 'cores' },
  { label: 'Volthubs', value: 'volthubs' },
]

export function Jumbotron({
  systemType,
  setSystemType,
  search,
  setSearch,
}: {
  systemType: SystemType
  setSystemType: Dispatch<SetStateAction<SystemType>>
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}) {
  const { user } = useAuthenticationContext()

  return (
    <div
      className="lg:bg-fixed bg-bottom bg-cover pt-28 sm:pt-36 pb-8 mb-8"
      style={{ backgroundImage: `url(${forest})`, zIndex: -10 }}
    >
      <div className="text-white w-11/12 xl:max-w-11/12 mx-auto md:px-auto xl:w-9/12">
        <div className="grid grid-rows-1 lg:grid-rows-2 xl:gap-3 gap-2 grid-cols-7">
          <div className="lg:col-start-1 lg:col-end-3 xl:row-start-1 sm:col-start-1 sm:col-end-4 col-start-1 col-end-5 h-14">
            <Dropdown
              list={systemTypes}
              name="type"
              onChange={({ value }) => {
                setSystemType(value)
              }}
              size="large"
              tabIndex={1}
              value={systemTypes.find(({ value }) => value === systemType) ?? systemTypes[0]}
            />
          </div>
          <div className="lg:col-start-3 lg:col-end-6 lg:row-start-1 row-start-2 col-start-1 col-end-8 h-14">
            <Search search={search} setSearch={setSearch} systemType={systemType} tabIndex={2} />
          </div>
          <div
            className={clsx(
              'lg:col-start-6 lg:col-end-7 lg:row-start-1 sm:row-start-1 sm:col-start-4 col-start-5 col-end-8 h-14',
              {
                'sm:col-end-6': isUserAllowedToProvision(user),
                'sm:col-end-8': !isUserAllowedToProvision(user),
              },
            )}
          >
            <QrButton tabIndex={3} />
          </div>
          {isUserAllowedToProvision(user) ? (
            <div className="lg:col-start-7 lg:col-end-8  lg:row-start-1 sm:row-start-1 sm:col-start-6 sm:col-end-8 col-start-1 col-end-8 row-start-3 h-14">
              <Provisioning tabIndex={4} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
