import { localOverrides } from './localOverride'
import devFavicon from './northvolt_dev.png'
import localhostFavicon from './northvolt_localhost.png'
// import stageFavicon from './northvolt_stage.png'

export type ReactAppConfig = {
  /**
   * this is the configuration for the authentication system and is tied to the "auth" terraform
   * module configuration
   * See /terraform/modules/auth/
   */
  REACT_APP_AUTHENTICATION:
    | null
    | ({
        logoutUri: string
        cognitoUserPoolWebClientId: string
        cognitoIssuer: string
        oauthDomain: string
        cookieDomain: string
        cookiePrefix: string
      } & (
        | {
            /**
             * if true, use the new auth flow using our own pages to handle
             * obtaining the access_token and refresh_token instead of reading them
             * from a cookie
             */
            newAuthFlow: true
            loginUri: string
            loginRedirectUri: string
            /**
             * must match the auth provider refresh_token validity
             * the token itself is not a JWT so we can't get the expire value from it
             */
            refreshTokenValidityDays: number
          }
        | {
            newAuthFlow: false
            loginUri: string
          }
      ))
  REACT_APP_ENVIRONMENT: 'production' | 'development' | 'localhost' | 'stage' | 'offline'
  REACT_APP_OFFLINE_REMOTE_ACCESS: boolean
  REACT_APP_VERSION: string
  REACT_APP_BASE_INVENTORY_URL: string
  REACT_APP_BASE_BATTERY_SYSTEM_URL: string
  REACT_APP_BASE_PORTAL_URL: string
  REACT_APP_BASE_WRAPPER_URL: string
  REACT_APP_BASE_DATALAKE_DOCS_URL: string
  REACT_APP_BASE_SPT_URL: string
  REACT_APP_SENTRY_REPORTING: boolean
  /**
    in localhost and offline modes we use the locally downloaded values to avoid CORS problems
    the folder is gitignored, run:
    AWS_PROFILE=bs-cb-prod make download-fault-search-instructions
    to fetch the current production version
  */
  REACT_APP_PORTAL_ASSETS_BASE_URL: string
  REACT_APP_SPIDER_API_URL: string
  REACT_APP_GRAPHQL_API: string
}

let conf: ReactAppConfig
const hostname = window.location.hostname

// @ts-ignore
const publicPath = window.__webpack_public_path__ ?? process.env.PUBLIC_PATH

function setFavicon(fileName: string) {
  const favicon: HTMLLinkElement | null = document.querySelector('link[rel=icon]')
  if (favicon != null) {
    favicon.href = `${publicPath}${fileName.slice(1)}`
  }
}

// this value should be set in index.html and is overriden in the Makefile after docker builds
// it contains the version number of the app
// @ts-ignore
const REACT_APP_VERSION = window.REACT_APP_VERSION

// this value is set in the index.html when running over the wireguard RAE proxy
// it puts the UI in offline mode and uses the proxy to fetch data
// @ts-ignore
const raeProxyPath: string | null = window.proxyPath ?? null

if (
  raeProxyPath == null &&
  localOverrides.offlineOverride == null &&
  // localhost
  (/.*localhost.*/.test(hostname) ||
    /.*127\.0\.0\.1*/.test(hostname) ||
    /.*0\.0\.0\.0*/.test(hostname)) &&
  // port 8000 means we are running in localrun mode (see /deploy/docker-compose-localrun.template.yaml)
  // we should use the same config as "offline" mode
  window.location.port !== '8000' &&
  // service-app port
  // we should use the same config as "offline" mode
  window.location.port !== '3004'
) {
  setFavicon(localhostFavicon)
  let REACT_APP_GRAPHQL_API = ''
  switch (localOverrides.apiOverride) {
    case 'prod':
      REACT_APP_GRAPHQL_API = 'https://systems.northvolt.com/api/gql'
      break
    case 'dev':
      REACT_APP_GRAPHQL_API = 'https://systems.dev.northvolt.com/api/gql'
      break
    case 'local':
      REACT_APP_GRAPHQL_API = 'http://localhost:3000/api/gql'
      break
    case 'batcloud':
      REACT_APP_GRAPHQL_API = 'https://api.systems.northvolt.com/query'
      break
  }
  conf = {
    REACT_APP_GRAPHQL_API,
    REACT_APP_AUTHENTICATION: {
      newAuthFlow: false,
      loginUri: `http://localhost:3000/auth/login`,
      logoutUri: `http://localhost:3000/auth/logout`,
      cognitoUserPoolWebClientId: '7ffid3utkutcuqqi35f9v7gaiq',
      cognitoIssuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_N6Y1XofFx',
      oauthDomain: 'auth.dev.bscb.se',
      cookieDomain: 'localhost',
      cookiePrefix: 'bscb_auth_localhost_',
    },

    REACT_APP_VERSION,
    REACT_APP_ENVIRONMENT: 'localhost',
    REACT_APP_OFFLINE_REMOTE_ACCESS: false,
    REACT_APP_BASE_BATTERY_SYSTEM_URL: 'http://localhost:3001',
    REACT_APP_BASE_INVENTORY_URL: 'http://localhost:3002',
    REACT_APP_BASE_PORTAL_URL: 'http://localhost:3010',
    REACT_APP_BASE_WRAPPER_URL: `https://systems.northvolt.com/wrapper`,
    // REACT_APP_BASE_WRAPPER_URL: 'https://systems.northvolt.com/wrapper',
    REACT_APP_BASE_SPT_URL: 'https://cloud.tp.northvolt.com/systems-products/#/product-tree',
    REACT_APP_BASE_DATALAKE_DOCS_URL:
      'http://localhost:3011/wrapper/batintel/dbt_documentation/index.html',
    REACT_APP_SENTRY_REPORTING: false,
    REACT_APP_PORTAL_ASSETS_BASE_URL: `${publicPath}assets/portal`,
    REACT_APP_SPIDER_API_URL: '',
  }
  if (window.location.port === '9100') {
    // running inside batcloud docker-compose (see /graphql/batcloud/docker-compose.local-full.yml)
    // when running batcloud locally it runs on port 80
    conf.REACT_APP_GRAPHQL_API = 'http://localhost:80/query'
    conf.REACT_APP_BASE_BATTERY_SYSTEM_URL = 'http://localhost:9100/batteries'
    conf.REACT_APP_BASE_INVENTORY_URL = 'http://localhost:9100/inventory'
  }
} else if (
  raeProxyPath == null &&
  localOverrides.offlineOverride == null &&
  /.*stage\.systems\.dev\.northvolt\.com.*/.test(hostname)
) {
  setFavicon(devFavicon)
  const domain = 'stage.systems.dev.northvolt.com'
  conf = {
    REACT_APP_AUTHENTICATION: {
      newAuthFlow: false,
      loginUri: `https://stage.systems.dev.northvolt.com/auth/login`,
      logoutUri: `https://stage.systems.dev.northvolt.com/auth/logout`,
      cognitoUserPoolWebClientId: '7ffid3utkutcuqqi35f9v7gaiq',
      cognitoIssuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_N6Y1XofFx',
      oauthDomain: 'auth.dev.bscb.se',
      cookieDomain: '.stage.systems.dev.northvolt.com',
      cookiePrefix: 'bscb_auth_dev_stage_',
    },
    REACT_APP_VERSION,
    REACT_APP_ENVIRONMENT: 'development',
    REACT_APP_OFFLINE_REMOTE_ACCESS: false,
    REACT_APP_BASE_BATTERY_SYSTEM_URL: `https://${domain}/batteries`,
    REACT_APP_BASE_INVENTORY_URL: `https://${domain}/inventory`,
    REACT_APP_BASE_PORTAL_URL: `https://${domain}/portal`,
    REACT_APP_BASE_WRAPPER_URL: `https://${domain}/wrapper`,
    REACT_APP_BASE_DATALAKE_DOCS_URL: `https://${domain}/wrapper/batintel/dbt_documentation/index.html`,
    REACT_APP_BASE_SPT_URL: 'https://cloud.tp-dev.northvolt.com/systems-products/#/product-tree',
    REACT_APP_SENTRY_REPORTING: true,
    REACT_APP_PORTAL_ASSETS_BASE_URL: `https://${domain}/assets/portal`,
    REACT_APP_SPIDER_API_URL: '',
    REACT_APP_GRAPHQL_API: `https://${domain}/api/gql`,
  }
} else if (
  raeProxyPath == null &&
  localOverrides.offlineOverride == null &&
  /.*systems\.dev\.northvolt\.com.*/.test(hostname)
) {
  setFavicon(devFavicon)
  const domain = 'systems.dev.northvolt.com'
  conf = {
    REACT_APP_AUTHENTICATION: {
      newAuthFlow: false,
      loginUri: `https://systems.dev.northvolt.com/auth/login`,
      logoutUri: `https://systems.dev.northvolt.com/auth/logout`,
      cognitoUserPoolWebClientId: '7ffid3utkutcuqqi35f9v7gaiq',
      cognitoIssuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_N6Y1XofFx',
      oauthDomain: 'auth.dev.bscb.se',
      cookieDomain: '.systems.dev.northvolt.com',
      cookiePrefix: 'bscb_auth_dev_',
    },
    REACT_APP_VERSION,
    REACT_APP_ENVIRONMENT: 'development',
    REACT_APP_OFFLINE_REMOTE_ACCESS: false,
    REACT_APP_BASE_BATTERY_SYSTEM_URL: `https://${domain}/batteries`,
    REACT_APP_BASE_INVENTORY_URL: `https://${domain}/inventory`,
    REACT_APP_BASE_PORTAL_URL: `https://${domain}/portal`,
    REACT_APP_BASE_WRAPPER_URL: `https://${domain}/wrapper`,
    REACT_APP_BASE_DATALAKE_DOCS_URL: `https://${domain}/wrapper/batintel/dbt_documentation/index.html`,
    REACT_APP_BASE_SPT_URL: 'https://cloud.tp-dev.northvolt.com/systems-products/#/product-tree',
    REACT_APP_SENTRY_REPORTING: true,
    REACT_APP_PORTAL_ASSETS_BASE_URL: `https://${domain}/assets/portal`,
    REACT_APP_SPIDER_API_URL: '',
    REACT_APP_GRAPHQL_API: `https://${domain}/api/gql`,
  }
} else if (
  raeProxyPath == null &&
  localOverrides.offlineOverride == null &&
  /.*stage\.systems\.northvolt\.com.*/.test(hostname)
) {
  // prod
  const domain = 'stage.systems.northvolt.com'
  conf = {
    REACT_APP_AUTHENTICATION: {
      newAuthFlow: false,
      loginUri: `https://stage.systems.northvolt.com/auth/login`,
      logoutUri: 'https://stage.systems.northvolt.com/auth/logout',
      cognitoUserPoolWebClientId: '1p7sj6o2n650rsuoi8er2vmhd1',
      cognitoIssuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_0okXten1w',
      oauthDomain: 'auth.bscb.se',
      cookieDomain: '.stage.systems.northvolt.com',
      cookiePrefix: 'bscb_auth_prod_stage_',
    },
    REACT_APP_VERSION,
    REACT_APP_ENVIRONMENT: 'production',
    REACT_APP_OFFLINE_REMOTE_ACCESS: false,
    REACT_APP_BASE_BATTERY_SYSTEM_URL: `https://${domain}/batteries`,
    REACT_APP_BASE_INVENTORY_URL: `https://${domain}/inventory`,
    REACT_APP_BASE_PORTAL_URL: `https://${domain}/portal`,
    REACT_APP_BASE_WRAPPER_URL: `https://${domain}/wrapper`,
    REACT_APP_BASE_SPT_URL: 'https://cloud.tp.northvolt.com/systems-products/#/product-tree',
    REACT_APP_BASE_DATALAKE_DOCS_URL: `https://${domain}/wrapper/batintel/dbt_documentation/index.html`,
    REACT_APP_SENTRY_REPORTING: true,
    REACT_APP_PORTAL_ASSETS_BASE_URL: `https://${domain}/assets/portal`,
    REACT_APP_SPIDER_API_URL: '',
    REACT_APP_GRAPHQL_API: `https://${domain}/api/gql`,
  }
} else if (
  raeProxyPath == null &&
  localOverrides.offlineOverride == null &&
  /.*systems\.northvolt\.com.*/.test(hostname)
) {
  // prod
  const domain = 'systems.northvolt.com'
  conf = {
    REACT_APP_AUTHENTICATION: {
      newAuthFlow: false,
      loginUri: `https://systems.northvolt.com/auth/login`,
      logoutUri: 'https://systems.northvolt.com/auth/logout',
      cognitoUserPoolWebClientId: '1p7sj6o2n650rsuoi8er2vmhd1',
      cognitoIssuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_0okXten1w',
      oauthDomain: 'auth.nvlt.co',
      cookieDomain: '.systems.northvolt.com',
      cookiePrefix: 'bscb_auth_prod_',
    },
    REACT_APP_VERSION,
    REACT_APP_ENVIRONMENT: 'production',
    REACT_APP_OFFLINE_REMOTE_ACCESS: false,
    REACT_APP_BASE_BATTERY_SYSTEM_URL: `https://${domain}/batteries`,
    REACT_APP_BASE_INVENTORY_URL: `https://${domain}/inventory`,
    REACT_APP_BASE_PORTAL_URL: `https://${domain}/portal`,
    REACT_APP_BASE_WRAPPER_URL: `https://${domain}/wrapper`,
    REACT_APP_BASE_SPT_URL: 'https://cloud.tp.northvolt.com/systems-products/#/product-tree',
    REACT_APP_BASE_DATALAKE_DOCS_URL: `https://${domain}/wrapper/batintel/dbt_documentation/index.html`,
    REACT_APP_SENTRY_REPORTING: true,
    REACT_APP_PORTAL_ASSETS_BASE_URL: `https://${domain}/assets/portal`,
    REACT_APP_SPIDER_API_URL: '',
    REACT_APP_GRAPHQL_API: `https://${domain}/api/gql`,
  }
} else {
  // offline
  let basepath = '/'
  if (localOverrides.offlineOverride != null) {
    basepath = `http://${localOverrides.offlineOverride}/`
  } else if (raeProxyPath != null) {
    basepath = raeProxyPath
  }
  conf = {
    REACT_APP_AUTHENTICATION: null,
    REACT_APP_VERSION,
    REACT_APP_ENVIRONMENT: 'offline',
    // window check to see if we are running inside /ui/apps/wrapper iframe
    REACT_APP_OFFLINE_REMOTE_ACCESS: raeProxyPath != null && window.self !== window.top,
    REACT_APP_BASE_BATTERY_SYSTEM_URL: '/',
    REACT_APP_BASE_INVENTORY_URL: '/',
    REACT_APP_BASE_PORTAL_URL: '/',
    REACT_APP_BASE_WRAPPER_URL: '/',
    REACT_APP_BASE_DATALAKE_DOCS_URL: '/',
    REACT_APP_BASE_SPT_URL: 'https://cloud.tp.northvolt.com/systems-products/#/product-tree',
    // do not report errors if we are using offlineOverride
    REACT_APP_SENTRY_REPORTING: localOverrides.offlineOverride == null,
    REACT_APP_PORTAL_ASSETS_BASE_URL: `${publicPath}assets/portal`,
    REACT_APP_SPIDER_API_URL: `${basepath}`,
    REACT_APP_GRAPHQL_API: `${basepath}query`,
  }
}

export const config = Object.freeze(conf)
