import clsx from 'clsx'
import { Suspense } from 'react'

import { Tooltip } from '@northvolt/tooltip'

import { DcirStatusQuery, gql, useGql } from '../../gql'

const dcirStatusQuery = gql`
  query DcirStatus($id: ID!) {
    batterySystem(id: $id) {
      dcirState
    }
  }
`

export function DcirStatusBadge({ dcirState }: { dcirState: string | null | undefined }) {
  switch (dcirState) {
    case 'high':
    case 'low':
      return (
        <Tooltip title={`DCIR Status: ${dcirState}`}>
          <div className={'flex items-center'}>
            <span
              className={clsx('inline-block w-2 h-2 ml-2 rounded-full', {
                'bg-NvBlue': dcirState === 'low',
                'bg-NvRed': dcirState === 'high',
              })}
            />
          </div>
        </Tooltip>
      )

    default:
      return null
  }
}

export function DcirStatusLoader({ systemId }: { systemId: string }) {
  const { data } = useGql<DcirStatusQuery>(dcirStatusQuery, {
    id: systemId,
  })

  const status = data?.batterySystem?.dcirState

  return <DcirStatusBadge dcirState={status} />
}

export function DcirStatus({ systemId }: { systemId: string }) {
  return (
    <Suspense fallback={null}>
      <DcirStatusLoader systemId={systemId} />
    </Suspense>
  )
}
