import { captureException } from '@sentry/react'
import { jwtDecode } from 'jwt-decode'
import { z } from 'zod'

type JwtValue = {
  readonly tokenString: string
  readonly decodedContents: any
  readonly expires: Date
}

export function createCookieString(
  name: string,
  value: string,
  expiration: Date,
  domain: string,
  secure: boolean,
) {
  const path = '/'

  const parts = []
  parts.push(`${name}=${encodeURIComponent(value)}`)
  parts.push(`expires=${expiration.toUTCString()}`)
  parts.push(`domain=${domain}`)
  parts.push(`path=${path}`)
  parts.push(`samesite=strict`)

  if (secure) {
    parts.push(`secure`)
  }

  return parts.join(';')
}

export function setCookie(
  name: string,
  value: string,
  expiration: Date,
  domain: string,
  cookieSecurity = window.location.hostname !== 'localhost',
) {
  console.info(
    '[Cookies] Setting cookie',
    'name:',
    name,
    'expiration:',
    expiration,
    'domain:',
    domain,
    'cookieSecurity:',
    cookieSecurity,
  )

  document.cookie = createCookieString(name, value, expiration, domain, cookieSecurity)
}

export function eraseCookie(
  name: string,
  domain: string,
  cookieSecurity = window.location.hostname !== 'localhost',
) {
  // set expiration date in the past effectively deletes the cookie
  const expiration = new Date(0)

  console.info(
    '[Cookies] Erasing cookie',
    'name:',
    name,
    'domain:',
    domain,
    'cookieSecurity:',
    cookieSecurity,
  )

  document.cookie = createCookieString(name, '', expiration, domain, cookieSecurity)
}

export function getCookieFromString(name: string, cookie: string) {
  const namePrefix = `${name}=`
  const cookies = cookie.split(';')

  for (let cookie of cookies) {
    cookie = cookie.trim()

    if (cookie.startsWith(namePrefix)) {
      const cookieValue = cookie.substring(namePrefix.length, cookie.length)
      return cookieValue || null
    }
  }

  return null
}

export function getCookie(name: string) {
  return getCookieFromString(name, document.cookie)
}

const jwtSchema = z
  .object({
    exp: z.number(),
  })
  .passthrough()

export function getJwtFromCookie(name: string): JwtValue | null {
  try {
    const tokenString = getCookie(name)
    if (tokenString == null) {
      return null
    }

    const jwtContents = jwtDecode(tokenString)
    const decoded = jwtSchema.parse(jwtContents)

    if (!('exp' in decoded)) {
      throw new Error(`Decoded JWT ${name} does not contain any expiration!`)
    }

    const expires = new Date(decoded.exp * 1000)
    return {
      tokenString,
      decodedContents: decoded as any,
      expires,
    }
  } catch (err) {
    captureException(err)
    return null
  }
}
