import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { Alert } from '@northvolt/alert'
import { Button } from '@northvolt/form'

export function SystemsMigrationNotification() {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!visible) {
      return
    }

    const AUTO_HIDE_TIMEOUT = 120 * 1000 // 2 minutes
    const timer = setTimeout(() => {
      setVisible(false)
    }, AUTO_HIDE_TIMEOUT)
    return () => {
      clearTimeout(timer)
    }
  }, [visible])

  return createPortal(
    <Transition show={visible} appear>
      <div
        className={clsx(
          'fixed bottom-0 left-0 right-0 p-4 z-[10000] pointer-events-none',
          'transition duration-300 ease-out data-[closed]:opacity-0 data-[closed]:translate-y-4',
        )}
      >
        <Alert
          className="mx-auto max-w-screen-sm !bg-[#f5fcff] shadow-lg pointer-events-auto  relative"
          action={
            <Button style="text-info" onClick={() => setVisible(false)}>
              Okay!
            </Button>
          }
          type="info"
        >
          <span className="font-semibold">We have migrated to a new authentication flow.</span>
          <span className="block">
            If you have issues (e.g. logging in repeatedly), please close any extra tabs that you
            have open. Thanks for your patience!
          </span>
        </Alert>
      </div>
    </Transition>,
    document.body,
  )
}
