import clsx from 'clsx'
import { PropsWithChildren, ReactNode, useCallback, useState } from 'react'

export type TooltipPlacementProps = 'right' | 'left' | 'top' | 'bottom'

export type TooltipProps = PropsWithChildren<{
  title?: ReactNode | null
  placement?: TooltipPlacementProps
  // prevents the tooltip from overflowing the window.
  dynamicPlacement?: boolean
  enabled?: boolean
  noWrap?: boolean
}>

export function Tooltip({
  title,
  children,
  placement = 'top',
  dynamicPlacement = true,
  enabled = true,
  noWrap = true,
}: TooltipProps) {
  const [leftCorrect, setLeftCorrect] = useState<number>(0)
  const [rightCorrect, setRightCorrect] = useState<number>(0)

  const tooltipRef = useCallback(
    (node: HTMLDivElement) => {
      if (node != null && dynamicPlacement) {
        const { x, width } = node.getBoundingClientRect()

        // 12 is width of the arrow pointing from the tooltip
        const arrowW = 12
        if (x < 0) {
          setLeftCorrect(x - width / 2 + arrowW)
        } else if (x + width - innerWidth > 0) {
          setRightCorrect(x + width - innerWidth + width / 2 - arrowW)
        }
      }
    },
    [dynamicPlacement],
  )

  if (!enabled) return <>{children}</>

  return (
    <div className="relative inline-flex flex-col items-center group">
      {children}
      {title && (
        <div
          className={clsx(
            'pointer-events-none w-auto absolute opacity-0 flex flex-col transition-opacity ease-in-out delay-100 group-hover:opacity-100 z-50',
            {
              ['items-center bottom-full mb-1']: placement === 'top',
              ['items-center top-full pt-50 mt-2']: placement === 'bottom',
              ['items-left left-full ml-4 -mt-1']: placement === 'right',
              ['items-right right-full mr-4 -mt-1']: placement === 'left',
            },
            { 'whitespace-nowrap': noWrap, 'whitespace-pre-wrap': !noWrap },
          )}
          ref={tooltipRef}
          style={{
            marginLeft: placement === 'top' || placement === 'bottom' ? -leftCorrect : undefined,
            marginRight: placement === 'top' || placement === 'bottom' ? rightCorrect : undefined,
          }}
        >
          <div className="rounded-sm p-2 text-base z-10 leading-none text-white bg-black select-none">
            {title}
          </div>
          <div
            className={clsx('w-2.5 h-2.5 rotate-45 bg-black', {
              ['-mt-1.5']: placement === 'top',
              ['absolute -top-1']: placement === 'bottom',
              ['absolute -left-1 top-2.5']: placement === 'right',
              ['absolute -right-1 top-2.5']: placement === 'left',
            })}
            style={{
              marginLeft: leftCorrect,
              marginRight: -rightCorrect,
            }}
          />
        </div>
      )}
    </div>
  )
}
