import clsx from 'clsx'
import { ComponentProps, PropsWithChildren } from 'react'

import { getTestId } from '@northvolt/test-utils'

type TableProps = PropsWithChildren<
  {
    className?: string
    border?: boolean
    testId?: string
  } & Omit<ComponentProps<'table'>, 'border'>
>

export function Table({ children, className, border, testId, ...restProps }: TableProps) {
  return (
    <table
      {...getTestId(testId)}
      className={clsx(
        'bg-white',
        { 'border-solid border border-gray-200': border !== false },
        className,
      )}
      {...restProps}
    >
      {children}
    </table>
  )
}
