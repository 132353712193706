import { useMemo } from 'react'
import useSWR from 'swr'

import { useGqlContext } from './GqlProvider'
import { serializeSwrKey } from './swrKey'
import { OptionsType, QueryType } from './types'

/**
 * Fetch some GraphQL data. Automatically handles refetching periodically and on window focus.
 * It also stops refetching if the window is hidden.
 *
 * Must be wrapped in Suspense and error boundaries, therefor the return data object is not-null
 *
 * The generic type Query is mostly for convenience, but should match the type generated (by schema codegen) for the query passed here.
 *
 * @param query A GraphQL query, either a plain string or a value returned by gql``
 * @param variables Query variables for this data fetch.
 * @param options Options
 */
export function useGql<Query, Variables extends Record<string, any> = {}>(
  query: QueryType | string,
  variables?: Variables,
  options?: OptionsType,
) {
  const { gqlSwrFetcher } = useGqlContext()
  const { refreshInterval } = options ?? {}

  // This will always return a result if the data is loaded.
  const result = useSWR<Query>(serializeSwrKey(query.toString(), variables ?? {}), {
    fetcher: gqlSwrFetcher as () => Query,
    suspense: true,
    errorRetryCount: 2,
    refreshWhenHidden: false,
    // see https://swr.vercel.app/docs/revalidation
    ...(refreshInterval != null
      ? {
          refreshInterval,
        }
      : {}),
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    focusThrottleInterval: 60000,
  })

  return useMemo(() => {
    return {
      // returning an object with "data" inside to allow for more things (pagination?)
      // values to be returned in the future without making a breaking change
      // we do not leak the other values returned by useSWR to not make this hook's API
      // dependent on SWR in case we want to change it
      data: result.data as Query,

      mutate: result.mutate as () => void,
    }
  }, [result.data, result.mutate])
}
