import { AuthUser, RolesEnum } from '@northvolt/cloud-auth'

export function isUserAllowedToProvision(user: AuthUser) {
  return (
    isUserAllowedToCreateCore(user) ||
    isUserAllowedToCreatePack(user) ||
    isUserAllowedToCreateHub(user) ||
    isUserAllowedToCreateFingerprint(user)
  )
}

export function isUserAllowedToCreateCore(user: AuthUser) {
  return user.tenant === 'northvolt'
}

export function isUserAllowedToCreatePack(user: AuthUser) {
  return (
    user.tenant === 'northvolt' ||
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN) ||
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION) ||
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_WRITER) ||
    user.roles.includes(RolesEnum.ROOT)
  )
}

export function isUserAllowedToCreateHub(user: AuthUser) {
  return user.tenant === 'northvolt'
}

export function isUserAllowedToCreateFingerprint(user: AuthUser) {
  return user.tenant === 'northvolt'
}

export function isUserAllowedToEdit(user: AuthUser) {
  return (
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN) ||
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION) ||
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_WRITER) ||
    user.roles.includes(RolesEnum.ROOT)
  )
}

export function isUserAllowedToChangeTenant(user: AuthUser) {
  return user.tenant === 'northvolt'
}

export function isUserAllowedToChangeCoreDisplayName(user: AuthUser) {
  return user.tenant === 'northvolt'
}

export function isUserAllowedToChangeHubMacAddress(user: AuthUser) {
  return user.tenant === 'northvolt'
}

export function isUserShownConnectOptions(user: AuthUser) {
  return user.tenant === 'northvolt'
}

export function isPowerUser(user: AuthUser) {
  return (
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION) ||
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN) ||
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_WRITER) ||
    user.roles.includes(RolesEnum.ROOT) ||
    user.tenant === 'northvolt'
  )
}

export function isAdminUser(user: AuthUser) {
  return (
    user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN) ||
    user.roles.includes(RolesEnum.ROOT) ||
    user.tenant === 'northvolt'
  )
}

export function isSuperAdminUser(user: AuthUser) {
  return (
    (user.roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN) ||
      user.roles.includes(RolesEnum.ROOT)) &&
    user.tenant === 'northvolt'
  )
}

export function isInternalUser(user: AuthUser) {
  return user.tenant === 'northvolt'
}
