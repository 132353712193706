import { SystemFragment } from '@northvolt/gql'

import { SystemType } from '../types'

const keys = {
  volthubs: 'recently_viewed_volthubs',
  voltpacks: 'recently_viewed_voltpacks',
  cores: 'recently_viewed_cores',
  all: 'recently_viewed_voltpacks_and_cores',
}

export function getRecent(type: SystemType): string[] {
  return getIds(type)
    .filter((val) => {
      return typeof val === 'string'
    })
    .reverse()
}

function getIds(type: SystemType): string[] {
  try {
    const lastViewedItems = JSON.parse(localStorage.getItem(keys[type]) ?? '[]')
    return lastViewedItems.filter((val: any) => {
      return typeof val === 'string'
    })
  } catch (e) {
    return []
  }
}

function setIds(ids: string[], type: SystemType) {
  localStorage.setItem(keys[type], JSON.stringify(ids))
}

export const saveRecent = (item: SystemFragment, type: SystemType) => {
  let lastViewedItems = getIds(type)

  if (lastViewedItems.includes(item.id)) {
    lastViewedItems = lastViewedItems.filter((id) => {
      return id !== item.id
    })
  }
  if (lastViewedItems.length >= 9) {
    lastViewedItems.shift()
  }
  lastViewedItems.push(item.id)
  setIds(lastViewedItems, type)
}
