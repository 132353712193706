import { ComponentProps, PropsWithChildren } from 'react'

import { getTestId } from '@northvolt/test-utils'

type TableBodyProps = PropsWithChildren<
  {
    testId?: string
  } & ComponentProps<'tbody'>
>

export function TableBody({ children, testId, ...restProps }: TableBodyProps) {
  return (
    <tbody {...getTestId(testId)} {...restProps}>
      {children}
    </tbody>
  )
}
