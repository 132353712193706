import { Transition } from '@headlessui/react'
import { PropsWithChildren } from 'react'

export function Fadein({ children }: PropsWithChildren) {
  return (
    <Transition
      as="div"
      appear={true}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
    >
      {children}
    </Transition>
  )
}
