import clsx from 'clsx'
import { ComponentProps, ForwardedRef, PropsWithChildren, forwardRef } from 'react'

import { getTestId } from '@northvolt/test-utils'

type TableHeadProps = PropsWithChildren<
  {
    sticky?: boolean
    /**
     * To control top height when the table head is sticky
     */
    top?: number
    className?: string
    testId?: string
  } & ComponentProps<'thead'>
>

function TableHeadInner(
  { children, className, sticky, top = 0, testId, ...restProps }: TableHeadProps,
  forwardedRef?: ForwardedRef<HTMLTableSectionElement>,
) {
  if (forwardedRef != null) {
    restProps.ref = forwardedRef
  }
  return (
    <thead
      className={clsx(className, {
        'z-10 sticky after:pointer-events-none border-t after:border-b bg-white after:border-gray-200 after:absolute after:right-0 after:bottom-0 after:left-0 after:top-0':
          sticky,
        'border-b': !sticky,
      })}
      style={{ top }}
      {...getTestId(testId)}
      {...restProps}
    >
      {children}
    </thead>
  )
}

export const TableHead = forwardRef(TableHeadInner)
