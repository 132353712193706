import clsx from 'clsx'
import { ComponentProps, PropsWithChildren } from 'react'

import { getTestId } from '@northvolt/test-utils'

type TableCellProps = PropsWithChildren<
  {
    verticalPadding?: 'none' | 'small' | 'medium' | 'large'
    highlight?: boolean
    className?: string
    testId?: string
  } & ComponentProps<'td'>
>

export function TableCell({
  children,
  verticalPadding,
  highlight,
  className,
  testId,
  ...restProps
}: TableCellProps) {
  return (
    <td
      {...getTestId(testId)}
      className={clsx(
        'text-base px-4',
        {
          'py-0': verticalPadding === 'none',
          'py-2': verticalPadding === 'small',
          'py-4': verticalPadding === 'medium' || verticalPadding == null,
          'py-8': verticalPadding === 'large',
          'font-medium text-black': highlight === true,
          'text-gray-900': highlight !== true,
        },
        highlight,
        className,
      )}
      {...restProps}
    >
      {children}
    </td>
  )
}
