import { Placement, useFloating } from '@floating-ui/react'
import {
  Menu as HLMenu,
  MenuButton as HLMenuButton,
  MenuItems as HLMenuItems,
} from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { getTestId } from '@northvolt/test-utils'

import { MenuItem, MenuItemProps } from './MenuItem'

export type MenuProps = {
  alignMenu?: 'left' | 'right'
  button: ReactNode
  className?: string
  list: MenuItemProps[]
  tabIndex?: number
  testId?: string
}

export function Menu({ alignMenu, button, className, list, testId }: MenuProps) {
  let placement: Placement = 'bottom'
  if (alignMenu === 'left') {
    placement = 'bottom-start'
  } else if (alignMenu === 'right') {
    placement = 'bottom-end'
  }

  const { refs, floatingStyles } = useFloating({ placement })

  return (
    <HLMenu as="div" className={clsx('whitespace-nowrap relative', className)}>
      <HLMenuButton ref={refs.setReference} as="div" {...getTestId(testId)}>
        {button}
      </HLMenuButton>
      <HLMenuItems
        ref={refs.setFloating}
        style={floatingStyles}
        className={clsx(
          'z-30 mt-2',
          'bg-white text-black-light rounded-md shadow-lg py-1.5 border border-black border-solid border-opacity-5 focus:outline-none',
        )}
      >
        {list.map((item, idx) => (
          <MenuItem key={`menuItem-${idx}`} {...item} />
        ))}
      </HLMenuItems>
    </HLMenu>
  )
}
