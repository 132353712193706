import { useCallback } from 'react'

import { useAuthenticationContext } from '@northvolt/cloud-auth'
import {
  Dropdown,
  Input,
  InputMultiselect,
  UseFormErrors,
  Validate,
  useForm,
} from '@northvolt/form'
import {
  EditVoltpackMobileHubQuery,
  SetVoltpackMobileHubDisplayNameMutation,
  SetVoltpackMobileHubDisplayNameMutationVariables,
  SetVoltpackMobileHubMacAddressMutation,
  SetVoltpackMobileHubMacAddressMutationVariables,
  SetVoltpackMobileHubTagsMutation,
  SetVoltpackMobileHubTagsMutationVariables,
  SetVoltpackMobileHubTenantMutation,
  SetVoltpackMobileHubTenantMutationVariables,
  gql,
  useGql,
  useGqlMutation,
} from '@northvolt/gql'

import { isUserAllowedToChangeHubMacAddress, isUserAllowedToChangeTenant } from '../../auth'

import { useEntityRelationships } from './useEntityRelationships'

const editVoltpackQuery = gql`
  query editVoltpackMobileHub($id: ID!) {
    batterySystem(id: $id) {
      id
      displayName
      tags
      meta {
        tenant
      }
      computers {
        id
      }
    }
  }
`

const setVoltpackMobileHubDisplayNameMutation = gql`
  mutation setVoltpackMobileHubDisplayName($id: ID!, $displayName: String!) {
    setVoltpackMobileHubDisplayName(id: $id, displayName: $displayName) {
      success
    }
  }
`

const setVoltpackMobileHubTenantMutation = gql`
  mutation setVoltpackMobileHubTenant($id: ID!, $tenant: ID!) {
    setVoltpackMobileHubTenant(id: $id, tenant: $tenant) {
      success
    }
  }
`

const setVoltpackMobileHubTagsMutation = gql`
  mutation setVoltpackMobileHubTags($id: ID!, $tags: [String!]!) {
    setVoltpackMobileHubTags(id: $id, tags: $tags) {
      success
    }
  }
`

const setVoltpackMobileHubMacAddressMutation = gql`
  mutation setVoltpackMobileHubMacAddress($id: ID!, $macAddress: String!) {
    setVoltpackMobileHubMacAddress(id: $id, macAddress: $macAddress) {
      success
    }
  }
`

export function EditVoltpackMobileHub({ id }: { id: string }) {
  const { user } = useAuthenticationContext()

  const { tenants, tags } = useEntityRelationships('voltpack-mobile-hub')

  const {
    data: { batterySystem },
    mutate,
  } = useGql<EditVoltpackMobileHubQuery>(editVoltpackQuery, { id })

  const { mutation: setDisplayName, result: displayNameResult } = useGqlMutation<
    SetVoltpackMobileHubDisplayNameMutation,
    SetVoltpackMobileHubDisplayNameMutationVariables
  >(setVoltpackMobileHubDisplayNameMutation)

  const { mutation: setTenant, result: tenantResult } = useGqlMutation<
    SetVoltpackMobileHubTenantMutation,
    SetVoltpackMobileHubTenantMutationVariables
  >(setVoltpackMobileHubTenantMutation)

  const { mutation: setTags, result: tagsResult } = useGqlMutation<
    SetVoltpackMobileHubTagsMutation,
    SetVoltpackMobileHubTagsMutationVariables
  >(setVoltpackMobileHubTagsMutation)

  const { mutation: setMacAddress, result: macAddressResult } = useGqlMutation<
    SetVoltpackMobileHubMacAddressMutation,
    SetVoltpackMobileHubMacAddressMutationVariables
  >(setVoltpackMobileHubMacAddressMutation)

  const setDisplayNameOnBlur = useCallback(
    async (value: string) => {
      await setDisplayName({
        id,
        displayName: value,
      })
      mutate()
    },
    [id, setDisplayName, mutate],
  )

  const setTenantOnSelected = useCallback(
    async (value: string) => {
      await setTenant({
        id,
        tenant: value,
      })
      mutate()
    },
    [id, setTenant, mutate],
  )

  const setTagsOnAdded = useCallback(
    async (value: string | string[]) => {
      await setTags({
        id,
        tags: value,
      })
      mutate()
    },
    [id, setTags, mutate],
  )

  const setMacAddressOnBlur = useCallback(
    async (value: string) => {
      await setMacAddress({
        id,
        macAddress: value,
      })
      mutate()
    },
    [id, setMacAddress, mutate],
  )

  type EditHubData = {
    displayName: string
    tenant: { value: string; label: string } | null
    macAddress: string
    tags: string[]
  }

  const { getFieldPropsByName, ...state } = useForm<EditHubData>({
    initialValues: {
      tenant: {
        value: batterySystem.meta.tenant,
        label: batterySystem.meta.tenant,
      },
      displayName: batterySystem.displayName ?? '',
      macAddress: batterySystem.computers?.[0]?.id ?? '',
      tags: batterySystem.tags as string[],
    },
    onValidate: (values) => {
      const errors: UseFormErrors<EditHubData> = {}
      if (values.displayName.length === 0) {
        errors.displayName = 'Please enter a display name.'
      }
      if (values.tenant == null) {
        errors.tenant = 'Please select a tenant.'
      }
      if (!Validate.testMacAddress(values.macAddress)) {
        errors.macAddress = Validate.macAddressAllowedFormats
      }
      return errors
    },
    onChange: (fieldName, formData, formErrors) => {
      if (fieldName === 'tenant' && formData.tenant != null && formErrors.tenant == null) {
        setTenantOnSelected(formData.tenant?.value)
      }
      if (fieldName === 'tags' && formErrors.tags == null) {
        setTagsOnAdded(formData.tags)
      }
    },
    onBlur: (fieldName, formData, formErrors) => {
      if (
        fieldName === 'displayName' &&
        formData.displayName != null &&
        formErrors.displayName == null
      ) {
        setDisplayNameOnBlur(formData.displayName)
      }
      if (
        fieldName === 'macAddress' &&
        formData.macAddress != null &&
        formErrors.macAddress == null
      ) {
        setMacAddressOnBlur(Validate.getMacAddressFromInput(formData.macAddress))
      }
    },
  })

  return (
    <>
      <Input
        className="mb-2"
        label="Display Name"
        state={displayNameResult?.state}
        tabIndex={1}
        {...getFieldPropsByName('displayName')}
        error={
          displayNameResult?.state === 'error'
            ? displayNameResult?.errors[0].message
            : state.errors.displayName
        }
      />
      {isUserAllowedToChangeTenant(user) && (
        <div className="mb-2">
          <Dropdown
            label="Tenant"
            list={tenants}
            placeholder="Select tenant"
            state={tenantResult?.state}
            tabIndex={2}
            {...getFieldPropsByName('tenant')}
            error={
              tenantResult?.state === 'error'
                ? tenantResult?.errors[0].message
                : state.errors.tenant
            }
          />
        </div>
      )}

      {isUserAllowedToChangeHubMacAddress(user) && (
        <Input
          className="mb-2"
          label="MAC Address"
          state={macAddressResult?.state}
          tabIndex={3}
          {...getFieldPropsByName('macAddress')}
          error={
            macAddressResult?.state === 'error'
              ? macAddressResult?.errors[0].message
              : state.errors.macAddress
          }
        />
      )}

      <InputMultiselect
        autocomplete={tags}
        className="mb-2"
        label="Tags"
        state={tagsResult?.state}
        tabIndex={4}
        {...getFieldPropsByName('tags')}
        error={tagsResult?.state === 'error' ? tagsResult?.errors[0].message : state.errors.tags}
      />
    </>
  )
}
