import { useCallback } from 'react'

import { useAuthenticationContext } from '@northvolt/cloud-auth'
import { Dropdown, Input, InputMultiselect, UseFormErrors, useForm } from '@northvolt/form'
import {
  EditPackQuery,
  SetPackDisplayNameMutation,
  SetPackDisplayNameMutationVariables,
  SetPackTagsMutation,
  SetPackTagsMutationVariables,
  SetPackTenantMutation,
  SetPackTenantMutationVariables,
  gql,
  useGql,
  useGqlMutation,
} from '@northvolt/gql'

import { isUserAllowedToChangeTenant } from '../../auth'

import { useEntityRelationships } from './useEntityRelationships'

const editPackQuery = gql`
  query editPack($id: ID!) {
    batterySystem(id: $id) {
      id
      displayName
      tags
      meta {
        tenant
      }
      computers {
        id
      }
    }
  }
`

const setPackDisplayNameMutation = gql`
  mutation setPackDisplayName($id: ID!, $displayName: String!) {
    setPackDisplayName(id: $id, displayName: $displayName) {
      success
    }
  }
`

const setPackTenantMutation = gql`
  mutation setPackTenant($id: ID!, $tenant: ID!) {
    setPackTenant(id: $id, tenant: $tenant) {
      success
    }
  }
`

const setPackTagsMutation = gql`
  mutation setPackTags($id: ID!, $tags: [String!]!) {
    setPackTags(id: $id, tags: $tags) {
      success
    }
  }
`

export function EditPack({ id }: { id: string }) {
  const { user } = useAuthenticationContext()

  const { tenants, tags } = useEntityRelationships('pack')

  const {
    data: { batterySystem },
    mutate,
  } = useGql<EditPackQuery>(editPackQuery, { id })

  const { mutation: setDisplayName, result: displayNameResult } = useGqlMutation<
    SetPackDisplayNameMutation,
    SetPackDisplayNameMutationVariables
  >(setPackDisplayNameMutation)

  const { mutation: setTenant, result: tenantResult } = useGqlMutation<
    SetPackTenantMutation,
    SetPackTenantMutationVariables
  >(setPackTenantMutation)

  const { mutation: setTags, result: tagsResult } = useGqlMutation<
    SetPackTagsMutation,
    SetPackTagsMutationVariables
  >(setPackTagsMutation)

  const setDisplayNameOnBlur = useCallback(
    async (value: string) => {
      await setDisplayName({
        id,
        displayName: value,
      })
      mutate()
    },
    [id, setDisplayName, mutate],
  )

  const setTenantOnSelected = useCallback(
    async (value: string) => {
      await setTenant({
        id,
        tenant: value,
      })
      mutate()
    },
    [id, setTenant, mutate],
  )

  const setTagsOnAdded = useCallback(
    async (value: string | readonly string[]) => {
      await setTags({
        id,
        tags: value,
      })
      mutate()
    },
    [id, setTags, mutate],
  )

  type EditPackData = {
    displayName: string
    tenant: { value: string; label: string } | null
    tags: string[]
  }

  const { getFieldPropsByName, ...state } = useForm<EditPackData>({
    initialValues: {
      tenant: {
        value: batterySystem.meta.tenant,
        label: batterySystem.meta.tenant,
      },
      displayName: batterySystem.displayName ?? '',
      tags: batterySystem.tags as string[],
    },
    onValidate: (values) => {
      const errors: UseFormErrors<EditPackData> = {}
      if (values.displayName.length === 0) {
        errors.displayName = 'Please enter a display name.'
      }
      if (values.tenant == null) {
        errors.tenant = 'Please select a tenant.'
      }
      return errors
    },
    onChange: (fieldName, formData, formErrors) => {
      if (fieldName === 'tenant' && formData.tenant != null && formErrors.tenant == null) {
        setTenantOnSelected(formData.tenant?.value)
      }
      if (fieldName === 'tags' && formErrors.tags == null) {
        setTagsOnAdded(formData.tags)
      }
    },
    onBlur: (fieldName, formData, formErrors) => {
      if (
        fieldName === 'displayName' &&
        formData.displayName != null &&
        formErrors.displayName == null
      ) {
        setDisplayNameOnBlur(formData.displayName)
      }
    },
  })

  return (
    <>
      <Input
        className="mb-2"
        label="Display Name"
        state={displayNameResult?.state}
        tabIndex={1}
        {...getFieldPropsByName('displayName')}
        error={
          displayNameResult?.state === 'error'
            ? displayNameResult?.errors[0].message
            : state.errors.displayName
        }
      />
      {isUserAllowedToChangeTenant(user) && (
        <>
          <div className="mb-2">
            <Dropdown
              label="Tenant"
              list={tenants}
              placeholder="Select tenant"
              state={tenantResult?.state}
              tabIndex={2}
              {...getFieldPropsByName('tenant')}
              error={
                tenantResult?.state === 'error'
                  ? tenantResult?.errors[0].message
                  : state.errors.tenant
              }
            />
          </div>
        </>
      )}

      <InputMultiselect
        autocomplete={tags}
        className="mb-2"
        label="Tags"
        state={tagsResult?.state}
        tabIndex={3}
        {...getFieldPropsByName('tags')}
        error={tagsResult?.state === 'error' ? tagsResult?.errors[0].message : state.errors.tags}
      />
    </>
  )
}
